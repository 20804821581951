import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import Layout from "../components/layout"

// Our Specialties Section
import RadiationTherapist from "../images/radiation-therapist.jpeg"
import Dosimetrist from "../images/dosimetrist.jpeg"
import Physicist from "../images/physicist.jpeg"

import marked from "marked"
import WebriqForm from "../components/form/form"

const OncologyPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allSanityPost.nodes

  useEffect(() => {
    const initializeWidget = () => {
      if (window.elfsightAPI && typeof window.elfsightAPI.init === "function") {
        window.elfsightAPI.init()
      } else {
        setTimeout(initializeWidget, 100)
      }
    }

    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.defer = true
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout location={location} title={siteTitle} page="oncology">
      <SEO title={siteTitle} description={siteDescription} />

      <div className="video-hero">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h1 className="hero-title">PeerSource Oncology</h1>
              <p className="hero-tagline">The Experts in Oncology Staffing</p>
            </Col>
          </Row>
        </Container>
        <video
          muted
          autoPlay
          poster="/assets/oncology-hero.jpg"
          style={{ objectPosition: "center 0px", top: "90px" }}
        >
          <source
            src="https://res.cloudinary.com/dwgikyj1p/video/upload/v1690205697/AdobeStock_198938431_pxy3qc.mp4"
            type="video/mp4"
          />
          <track src="" kind="captions" srclang="en" label="English"></track>
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="work-with-onco">
        <Container>
          <Row>
            <Col md={11} lg={10} className="mx-auto text-center">
              <div className="section-content">
                <h2 className="new-h2 text-white text-center">
                  What you get working with PeerSource Oncology
                </h2>
                <p className="text-white">
                  PeerSource Oncology pairs healthcare organizations with the
                  top locum Radiation Oncology professionals, ready to
                  supplement your team with short and long-term assignments no
                  matter where you are in the country. With over 25 years of
                  combined Radiation Oncology staffing experience, we are the
                  experts in finding the right candidates for your staffing
                  needs.
                </p>
                <div>
                  <Link className="new-btn white" href="/oncology/contact-us/">
                    Contact Us Today
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div id="our-specialties" className="onco-specialties">
        <Container>
          <div className="section-content">
            <h2 className="new-h2 text-center">Our Specialties</h2>
            <Row className="justify-content-center">
              {specialties?.map(item => (
                <Col sm={6} md={4}>
                  <div className="specialty-card">
                    <div
                      className="image"
                      style={{ backgroundImage: `url(${item?.image})` }}
                    ></div>
                    {(item?.title || item?.desc) && (
                      <div className="card-body">
                        {item?.title && (
                          <h3 className="card-title">{item?.title}</h3>
                        )}
                        {item?.desc && <p>{item.desc}</p>}
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>

      <div id="about-peersource-oncology">
        <Container>
          <Row>
            <Col md={6}>
              <div className="section-content">
                <h2 className="new-h2">About PeerSource Oncology</h2>
                <div>
                  <h3>Who are we?</h3>
                  <p>
                    PeerSource Oncology is a recruiting firm that specializes in
                    the placement of Radiation Oncology Professionals
                    nationwide. With over 25 years of experience in Radiation
                    Oncology recruiting, we provide an unparalleled experience
                    for both our clients and candidates.
                  </p>
                </div>
                <div>
                  <h3>What separates us?</h3>
                  <p>
                    We are a boutique firm with a focus on providing clients and
                    candidates exactly what they are looking for in an employee
                    and a position. As a small organization, we take pride in
                    forging long-lasting relationships with everyone involved in
                    the process.
                  </p>
                </div>
                <div>
                  <h3>Why PeerSource?</h3>
                  <p>
                    With the ever-growing popularity of large corporations
                    wading into the Radiation Oncology placement industry,
                    PeerSource Oncology continues to provide the best talent to
                    our clients in a small business atmosphere. Our internal
                    employees have a wealth of knowledge and do an incredible
                    job matching candidates to positions. Our goal is to provide
                    multiple fully-vetted candidates to clients for contract
                    opportunities where our client feels they have the option to
                    select, rather than settle.
                  </p>
                </div>

                <div>
                  <Link to="/contact-us" className="new-btn">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="google-review home-review">
        <Container>
          <div className="section-content">
            <h2 className="new-h2 text-center mb-5">Testimonials</h2>

            {/* <div
              className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
              data-elfsight-app-lazy
            ></div> */}
            <div
              className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
              data-elfsight-app-lazy
              // data-embed-id="25369363"
            ></div>
          </div>
          <div className="overlay" />
        </Container>
      </div>

      <div id="news" className="py-100 blog-section">
        <Container className="latestNews">
          <Row className="mt-5 justify-content-center">
            <Col lg={3} md={12} className="mb-5">
              <h2 className="position-relative mb-4">Featured Posts</h2>

              <div className="mb-5">
                {" "}
                <Link to="/news" className="blue-link ">
                  from our blog
                </Link>
              </div>

              <h6>
                Sign-up to be notified <br />
                of new posts?
              </h6>

              <WebriqForm
                name="Subscribe Blog"
                className="webriq-form"
                form_id="7050eedd-8d8f-4ed8-b5de-f00c71644cc8"
              >
                <div class="form-group">
                  <label className="d-none" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter Your Email Here..."
                    name="email"
                    id="email"
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <button className="common-btn border-none" type="submit">
                  Send
                </button>
              </WebriqForm>
            </Col>
            <Col lg={9} md={12}>
              <Row>
                {posts.map((post, index) => (
                  <Col md={6} className="mb-5" key={index}>
                    <Link to={`/${post.slug?.current}`}>
                      <div className="blog-item bg-light">
                        {/*<LazyLoadImage
                          className="img-fluid mb-5"
                          src={post.mainImage?.asset?.fluid?.src}
                          alt={post.mainImage?.alt}
                        />
                        */}

                        <div
                          className="bg-blog position-relative"
                          style={{
                            backgroundImage: `url(${post.mainImage?.asset?.fluid?.src})`,
                          }}
                        >
                          <div className="blog-date">
                            <p>
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {post.publishedAt}
                            </p>
                          </div>
                        </div>

                        <div className="blog-body">
                          <h3 className="mb-4">{post.title}</h3>
                          <div
                            className="blog-excerpt"
                            dangerouslySetInnerHTML={{
                              __html: marked(post.excerpt),
                            }}
                          />
                        </div>

                        <div className="blog-foot">
                          <Row>
                            <Col md={7}>
                              {post.authors.length !== 0 && (
                                <p>
                                  <i
                                    className="fa fa-user"
                                    ariaHidden="true"
                                    style={{ color: "#00C1C8" }}
                                  ></i>{" "}
                                  {post.authors[0].person.name}
                                </p>
                              )}
                            </Col>
                            <Col md={5} className="text-right">
                              <p>
                                <Link
                                  to={`/${post?.slug?.current}`}
                                  style={{ color: "#00C1C8" }}
                                >
                                  <small>
                                    Read More{" "}
                                    <i
                                      className="fa fa-arrow-right"
                                      ariaHidden="true"
                                    ></i>
                                  </small>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="call-us-cta">
        <Container>
          <Row className="align-items-center">
            <Col xl={7} className="text-center text-xl-left">
              <h2 className="new-h2 cta-title text-white">
                Give us a call today to find out how{" "}
                <span style={{ color: "#0088A0" }}>PeerSource</span> can help
                YOU.
              </h2>
              <p
                style={{ marginTop: "30px" }}
                className="text-white lead font-italic"
              >
                We work across the entire US and staff in all 50 states.
              </p>
            </Col>
            <Col xl={5} className="text-center text-xl-right my-5">
              <a
                href="tel:941-421-3330"
                target="_blank"
                rel="noreferrer"
                className="new-btn huge"
                style={{ display: "inline-flex", alignItems: "center" }}
              >
                <i className="fa fa-phone lead" />
                <span className="ml-2">941-421-3330</span>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default OncologyPage

const specialties = [
  {
    title: "Radiation Therapists",
    image: RadiationTherapist,
  },
  {
    title: "Medical Dosimetrists",
    image: Dosimetrist,
  },
  {
    title: "Medical Physicists",
    image: Physicist,
  },
]

export const oncologyPageQuery = graphql`
  query oncologyPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }

    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 2) {
      nodes {
        id
        title
        publishedAt(formatString: "MMM-DD-YYYY")
        mainImage {
          asset {
            fluid {
              src
            }
          }
          alt
        }
        excerpt
        slug {
          current
        }
        authors {
          person {
            name
          }
        }
      }
    }
  }
`
